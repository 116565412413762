@import "./reset.css";
@import "./globals.css";
@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply w-full mx-auto;
    background-color: #070809;
  }

  h1,
  h2,
  h3 {
    @apply font-header;
  }

  .container {
    @apply max-w-[1600px] mx-auto px-5;
  }
  /* ... */
  :root {
    --radius: 0.5rem;
  }
}

.ag-theme-quartz-dark {
  --ag-row-hover-color: rgba(207, 255, 63, 0.05) !important;
  --ag-row-height: 10px;
  --ag-grid-size: 3px;
  --ag-list-item-height: 30px;
  --ag-font-size: 10px;
}

.ag-theme-quartz-dark .ag-cell {
  font-size: 16px;
}
.ag-theme-quartz-dark .ag-header-cell {
  /* font-size: 18px; */
  @apply text-white/40;
}

.fr-article h2{
  color: rgb(240 89 94 / var(--tw-text-opacity));
  font-size: 3rem;
  line-height: 1; 
  font-family: Anton, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.fr-article h3{
  color: rgb(240 89 94 / var(--tw-text-opacity));
  font-size: 2rem;
  line-height: 1; 
  font-family: Anton, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.fr-article h4{
  color: white;
  font-size: 3rem;
  line-height: 1; 
  font-family: Anton, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.fr-article a{
  color: rgb(207 255 63 / var(--tw-text-opacity));
  text-decoration: underline;
}

/* Styles for numbered list with a specific font for numbers */
.fr-article ol {
  list-style-type: none; /* Remove default numbering */
  padding-left: 1.5em; /* Space for custom numbering */
  margin-left:1.2rem
}

.fr-article ol li {
  position: relative;
  font-family: Archivo, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.fr-article ol li::before {
  content: counter(item) ". "; /* Add the numbering */
  counter-increment: item; /* Increment the counter */
  position: absolute;
  left: -1rem;
  top: 0;
  font-family: 'Times New Roman', serif; /* Custom font for the numbers */
  font-size: 1rem; /* Size for the numbers */
  color: #ff6347; /* Color for the numbers */
  color: white;
  font-size: 1rem;
  font-family: Archivo, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

/* Initialize the counter */
.fr-article ol {
  counter-reset: item; /* Start the counter at 1 */
}

.fr-article ul {
  list-style-type: disc; /* Default bullet */
  padding-left: 1.5em; /* Adds space for the bullets */
  font-family: Arial, sans-serif; /* Font for the list items */
  font-size: 1rem; /* Font size for the list items */
  color: rgb(174 175 177 / var(--tw-text-opacity)); /* Color for the list items */
  margin-left: 1.2rem;
  
}

/* Custom bullet point with circle */
.fr-article ul li {
  list-style-type: disc; /* Circle bullets */
  
}


.fr-article img {
  margin: 0 auto;
  width: 95%;
  height: auto;


}

